import * as React from "react";
import { Link } from "gatsby";

// styles

const container = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100vw",
  alignItems: "center",
  fontSize: 36,
};
const buttonStyle = {
  width: 100,
  height: 55,
  border: 0,
  background: "#00EE5F",
  boxShadow: "2px 2px 5px black",
};
const linkStyles = {
  display: "block",
  textAlign: "center",
  lineHeight: 4,
  color: "black",
  width: 100,
  height: 55,
  // fontSize: 24,
};

const paragraph = {
  margin: 26,
};

// markup
const NotFoundPage = () => {
  return (
    <div style={container}>
      <p style={paragraph}>
        {" "}
        Tyvärr kunde vi inte hitta sidan du letade efter!{" "}
      </p>
      <button style={buttonStyle}>
        <Link style={linkStyles} to="/">
          Hem
        </Link>
      </button>
    </div>
  );
};

export default NotFoundPage;
